import "./App.css";
import HeroSection from "./components/HeroSection";
import Feature from "./components/FeatureSection";
import FaqsSection from "./components/FaqsSection";
import SignUpSection from "./components/SignUpSection";
import WebFooter from "./components/common/footer";

function App() {
  return (
    <div className="App">
      <HeroSection />
      <Feature
        title="Marketplace"
        description="Discover the world with our audio guide tours and city exploration app, offering GPS-based tours for an immersive travel experience. Access our marketplace for personalized journeys, enjoy cultural immersion, and connect with local tour guides. Your adventure awaits, guided by our app to hidden wonders."
        imageUrl="/images/tourist.jpg"
        reverse={true}
      />
      <Feature
        title="Tour creation tool-box"
        description="Enhance your storytelling with our intuitive platform, designed for seamless creation of GPS tours. Incorporate photos and voiceovers easily, transforming insights into memorable journeys. Ideal for anyone eager to share their passion, our tools help bring customized city explorations and cultural narratives to life, engaging explorers with every step."
        imageUrl="/images/podcast.jpg" // Replace with your actual image path
        reverse={false}
      />
      <Feature
        title="Dashboard highlights"
        description="Boost your tour sales using our analytics dashboard. Track revenue, evaluate tour performance with real-time data, and use feedback to enhance your offerings. This tool is key for increasing reach and engagement, ensuring your tours make a global impact."
        imageUrl="/images/dashboard.jpg"
        reverse={true}
      />
      <Feature
        title="AI-Enhanced Content Creation"
        description="
        Elevate your travel storytelling with AI-enhanced audio guide creation tools. Craft interactive experiences that captivate and educate, ensuring your customized tours exceed quality expectations. Use AI for deeper audience engagement, making every cultural journey an unforgettable exploration."
        imageUrl="/images/robot.jpg"
        reverse={false}
      />
      <hr></hr>
      <SignUpSection />
      <hr></hr>
      <FaqsSection />
      <WebFooter />
    </div>
  );
}

export default App;
