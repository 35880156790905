import React from "react";
import "./faqsSection.css";
import Faq from "react-faq-component";

const data = {
  title: "FAQs",
  rows: [
    {
      title: "What is Journi?",
      content:
        "Journi transforms your smartphone into a personal tour guide, offering immersive audio tours narrated by locals. Explore cities at your leisure with an interactive map that highlights must-see attractions, each story brought to life by the voices of local experts. Leveraging AI, Journi personalizes your journey, delivering recommendations and insights uniquely tailored to your interests, making every exploration an adventure tailored just for you.",
    },
    {
      title: "How does Journi work?",
      content:
        "Discover cities through the eyes and voices of locals. Tour guides share their crafted audio tours on Journi, allowing you to purchase and download them directly to your smartphone. Wander with purpose, guided by an interactive map that leads you to curated points of interest. Listen to fascinating stories and historical facts at your own pace, experiencing the city's heartbeat through its landmarks and hidden gems.",
    },
    {
      title: "What types of tours are available on Journi?",
      content:
        "Journi's library spans a spectrum of audio tours, from the echoes of history at ancient landmarks to the bustling streets of culinary havens. Whether you're after cultural insights, seeking culinary adventures, or looking to uncover the secrets of the path less traveled, our local tour guides have curated experiences just for you. Each tour is an invitation to see the world through a local lens.",
    },
    {
      title: "How do I purchase tours on Journi?",
      content:
        "Embarking on your next adventure is just a few taps away. Browse Journi's collection in the app, select a tour that resonates with your curiosity, and proceed with payment. Your chosen tour will then be available for download, ready to accompany you offline, ensuring a seamless exploration experience.",
    },
    {
      title: "Who are the tour guides on Journi?",
      content:
        "Meet the storytellers behind the voice—our tour guides are locals with deep-rooted connections to their cities. From historians to culinary aficionados, they bring a wealth of knowledge and passion to each narrative, offering not just tours but experiences enriched with personal insights and recommendations. Discover the city through their eyes, from walking paths to scenic drives and bicycle routes.",
    },
    {
      title: "Can Journi work in a museum?",
      content:
        "Absolutely! Journi extends its interactive experience to museums, allowing the integration of museum maps and the creation of numbered stops for each exhibit. Navigate through collections and exhibits with ease, enriched by audio narratives that bring history and art to life.",
    },
    {
      title: "Can I create and sell tours on Journi?",
      content:
        "Journi invites local tour guides to share their city's stories by creating and publishing audio tours. It's your platform to showcase your expertise, connect with a global audience, and earn by sharing the unique tales and hidden spots of your city. Craft your tour, set your price, and start sharing your insights with the world.",
    },
    {
      title: "Is Journi available in multiple languages?",
      content:
        "Embracing diversity, Journi offers tours in various languages, ensuring travelers from around the globe can enjoy immersive experiences in the language they're most comfortable with. Our commitment to inclusivity means you can explore new places with confidence, guided by voices that speak your language.",
    },
    {
      title: "What are the fees and commissions for guides for each sale?",
      content:
        "Exciting news for our guides! When you join the Journi family, we're giving you a special welcome gift: zero Journi commission fees for your first year. This means you keep more of what you earn from each tour sold! Please note, while we'd love to cover everything, the standard fees for transactions through the Play Store or App Store will still apply. It's our way of saying thank you for sharing your world with us and our travelers.",
    },
  ],
};

const styles = {
  bgColor: "white",
  titleTextColor: "black",
  rowTitleColor: "black",
  rowContentColor: "grey",
  arrowColor: "red",
};

const config = {
  animate: true,
  arrowIcon: "V",
  tabFocus: true,
};

const FaqsSection = () => (
  <div id="faqs" className="faqs">
    <Faq data={data} styles={styles} config={config} />
  </div>
);

export default FaqsSection;
