import React, { useEffect, useRef } from "react";
import "./heroSection.css"; // Ensure the path to your CSS file is correct

const HeroSection = () => {
  const heroContentRef = useRef(null); // Reference to the hero content

  useEffect(() => {
    if (heroContentRef.current) {
      const contentPosition = heroContentRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const contentHeight = contentPosition.height;
      const offsetTop = contentPosition.top;
      const centerPosition = viewportHeight / 2 - contentHeight / 2;
      const scrollPosition = offsetTop + window.pageYOffset - centerPosition;

      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth", // Optional: adds smooth scrolling
      });
    }
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div className="hero">
      <div className="hero-left">
        <img
          src="/images/tourimage.avif"
          alt="Descriptive Alt Text"
          className="hero-image"
        />{" "}
        {/* Ensure your image path is correct */}
      </div>
      <div className="hero-right">
        <div className="hero-content" ref={heroContentRef}>
          <h1 className="hero-heading">
            Empower Your Tours <br /> Reach a Global Audience
          </h1>
          <p className="hero-subtext">
            The platform for modern tour guides. Create immersive experiences.
            <br />
            Connect with travelers. Let your tours shine. Earn.
          </p>
          <a data-umami-event="cta click" href="/#sign-up" className="hero-cta">
            <b>Register Today</b>
          </a>
          <a data-umami-event="freeyear click" href="/#faqs">
            <div className="hero-info">Enjoy 1 year commissions-free</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
