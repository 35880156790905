import { Footer } from "flowbite-react";
// import { BsLinkedin, BsInstagram, BsTwitter } from "react-icons/bs";

const WebFooter = () => (
  <Footer container>
    <div className="w-full text-center">
      <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
        <Footer.Brand
          href="#"
          src="./images/logo.svg"
          alt="Journi Logo"
          name="Journi"
        />
        <Footer.LinkGroup>
          <Footer.Link href="mailto:hello@journi.pro">Contact</Footer.Link>
        </Footer.LinkGroup>
      </div>
      <Footer.Divider />
      <div className="w-full sm:flex sm:items-center sm:justify-between">
        <Footer.Copyright href="#" by="Journi" year={2024} />
        <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
          {/* <Footer.Icon href="#" icon={BsInstagram} />
          <Footer.Icon href="#" icon={BsTwitter} />
          <Footer.Icon href="#" icon={BsLinkedin} /> */}
        </div>
      </div>
    </div>
  </Footer>
);

export default WebFooter;
